/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");

button.mat-mdc-button .mat-icon,
a.mat-mdc-button .mat-icon,
a.mat-mdc-raised-button .mat-icon,
a.mat-mdc-unelevated-button .mat-icon,
a.mat-mdc-outlined-button .mat-icon {
  vertical-align: top;
  font-size: 1.25em;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
