.preloader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #fff;
}
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 10px auto;
  margin-bottom: 40px;
}

.loadingimg {
  padding-top: 10%;
  margin: auto;
  width: 80%;
  text-align: center;
}

.lodingtext {
  color: #000;
  font-weight: 600;
  text-align: center;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce1 {
  background-color: #e6508a;
}

.double-bounce2 {
  background-color: #1b5674;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
