/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. Posts
 *  7. Widgets
 *  8. Custom Templates
 */
/******************* 
Global Styles 
*******************/

mat-table {
    .mdc-checkbox {
        height: 8px !important;
        width: 8px !important;

        .mdc-checkbox__background {
            height: 12px !important;
            width: 12px !important;
        }

        .mdc-checkbox__checkmark {
            height: 8px !important;
            width: 8px !important;
        }

        .mdc-checkbox__mixedmark {
            height: 8px !important;
            width: 8px !important;
        }
    }
}

.autocomplete-container .suggestions-container {
    width: unset !important;
}

.mat-mdc-dialog-actions {
    margin-left: 12px !important;
    margin-bottom: 12px !important;
    justify-content: start;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset !important;
}

.mdc-button {
    justify-content: unset !important;
    min-width: unset !important;
}

.mat-mdc-form-field-infix {
    min-height: unset !important;
}

.mat-mdc-table {
    width: 100% !important;
}

.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
    margin-left: 4px !important;
}

* {
    outline: none;
    box-sizing: border-box;
}

body {
    margin: 0;
    overflow-x: hidden;
    font-weight: 300;
    background: #f0f0f0; /* Default background color */
}

@-moz-document url-prefix() {
    body {
        font-weight: 350 !important;
    }
}

html {
    position: relative;
    min-height: 100%;
    background: #ffffff; /* Default background color */
}
a:hover,
a:focus,
a {
    text-decoration: none;
}
a.link {
    text-decoration: none;
    &:hover,
    &:focus {
        /* Custom hover color can be defined here */
    }
}
.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.img-rounded {
    border-radius: 4px; /* Default border radius */
}
html body .mdi:before,
html body .mdi-set {
    line-height: initial;
}
code {
    padding: 0.2rem 0.4rem;
    font-size: 90%;
    background-color: #f8f8f8; /* Default light background */
    border-radius: 0.25rem;
}

.shadow-none {
    box-shadow: none !important;
}
.ml-auto {
    margin-left: auto;
}
.m-auto {
    margin: auto;
}
.mr-auto {
    margin-right: auto;
}
.rounded {
    border-radius: 4px; /* Default border radius */
}
/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif; /* Default heading font */
    font-weight: 400;
}

h1 {
    line-height: 40px;
    font-size: 36px;
}

h2 {
    line-height: 36px;
    font-size: 30px;
}

h3 {
    line-height: 30px;
    font-size: 21px;
}

h4 {
    line-height: 22px;
    font-size: 18px;
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}
html,
body {
    .display-5 {
        font-size: 3rem;
    }
    .display-6 {
        font-size: 36px;
    }
}
.box {
    border-radius: 4px; /* Default border radius */
    padding: 10px;
}
html body {
    .dl {
        display: inline-block;
    }
    .db {
        display: block;
    }
    .op-5 {
        opacity: 0.5;
    }
    .op-3 {
        opacity: 0.3;
    }
}
.no-wrap {
    td,
    th {
        white-space: nowrap;
    }
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.d-flex {
    display: flex;
}
.no-shrink {
    flex-shrink: 0;
}
.oh {
    overflow: hidden;
}
/*******************
Blockquote
*******************/

html body blockquote {
    border: 1px solid #e0e0e0; /* Default border color */
    border-left: 5px solid #2196f3; /* Default theme color */
    padding: 15px;
    margin: 20px 0;
}

.clear {
    clear: both;
}
ol li {
    margin: 5px 0;
}

/*******************
Paddings
*******************/
html body {
    .p-0 {
        padding: 0px;
    }

    .p-10 {
        padding: 10px;
    }

    .p-20 {
        padding: 20px;
    }

    .p-30 {
        padding: 30px;
    }

    .p-l-0 {
        padding-left: 0px;
    }

    .p-l-10 {
        padding-left: 10px;
    }

    .p-l-20 {
        padding-left: 20px;
    }

    .p-r-0 {
        padding-right: 0px;
    }

    .p-r-10 {
        padding-right: 10px;
    }

    .p-r-20 {
        padding-right: 20px;
    }

    .p-r-30 {
        padding-right: 30px;
    }

    .p-r-40 {
        padding-right: 40px;
    }

    .p-t-0 {
        padding-top: 0px;
    }

    .p-t-5 {
        padding-top: 5px;
    }

    .p-t-10 {
        padding-top: 10px;
    }

    .p-t-20 {
        padding-top: 20px;
    }

    .p-t-30 {
        padding-top: 30px;
    }

    .p-b-0 {
        padding-bottom: 0px;
    }

    .p-b-5 {
        padding-bottom: 5px;
    }

    .p-b-10 {
        padding-bottom: 10px;
    }

    .p-b-20 {
        padding-bottom: 20px;
    }

    .p-b-30 {
        padding-bottom: 30px;
    }

    .p-b-40 {
        padding-bottom: 40px;
    }
}

/*******************
Margin
*******************/
html body {
    .m-0 {
        margin: 0px;
    }

    .m-l-5 {
        margin-left: 5px;
    }

    .m-l-10 {
        margin-left: 10px;
    }

    .m-l-15 {
        margin-left: 15px;
    }

    .m-l-20 {
        margin-left: 20px;
    }

    .m-l-30 {
        margin-left: 30px;
    }

    .m-l-40 {
        margin-left: 40px;
    }

    .m-r-5 {
        margin-right: 5px;
    }

    .m-r-10 {
        margin-right: 10px;
    }

    .m-r-15 {
        margin-right: 15px;
    }

    .m-r-20 {
        margin-right: 20px;
    }

    .m-r-30 {
        margin-right: 30px;
    }

    .m-r-40 {
        margin-right: 40px;
    }

    .m-t-0 {
        margin-top: 0px;
    }

    .m-t-5 {
        margin-top: 5px;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-t-15 {
        margin-top: 15px;
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-30 {
        margin-top: 30px;
    }

    .m-t-40 {
        margin-top: 40px;
    }

    .m-b-0 {
        margin-bottom: 0px;
    }

    .m-b-5 {
        margin-bottom: 5px;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    .m-b-15 {
        margin-bottom: 15px;
    }

    .m-b-20 {
        margin-bottom: 20px;
    }

    .m-b-30 {
        margin-bottom: 30px;
    }

    .m-b-40 {
        margin-bottom: 40px;
    }
}

/*******************
vertical alignment
*******************/
html body {
    .vt {
        vertical-align: top;
    }

    .vm {
        vertical-align: middle;
    }

    .vb {
        vertical-align: bottom;
    }
}
/*******************
Opacity
*******************/
.op-5 {
    opacity: 0.5;
}
/*******************
font weight
*******************/
html body {
    .font-bold {
        font-weight: 700;
    }

    .font-normal {
        font-weight: normal;
    }

    .font-light {
        font-weight: 300;
    }
    .font-medium {
        font-weight: 500;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-10 {
        font-size: 10px;
    }
    .font-12 {
        font-size: 12px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
}
/*******************
Border
*******************/
html body {
    .b-0 {
        border: none;
    }
    .b-r {
        border-right: 1px solid #e0e0e0; /* Default border color */
    }
    .b-l {
        border-left: 1px solid #e0e0e0; /* Default border color */
    }
    .b-b {
        border-bottom: 1px solid #e0e0e0; /* Default border color */
    }
    .b-t {
        border-top: 1px solid #e0e0e0; /* Default border color */
    }
    .b-all {
        border: 1px solid #e0e0e0; /* Default border color */
    }
}
/*******************
Thumb size
*******************/

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}
.hide {
    display: none;
}
.img-circle {
    border-radius: 100%;
}
.radius {
    border-radius: 4px; /* Default border radius */
}
/*******************
Text Colors
*******************/
.text-white {
    color: #ffffff !important; /* Default white color */
}

.text-danger {
    color: #f44336 !important; /* Default red color */
}

.text-muted {
    color: #9e9e9e !important; /* Default muted color */
}

.text-warning {
    color: #ff9800 !important; /* Default orange color */
}

.text-success {
    color: #4caf50 !important; /* Default green color */
}

.text-info {
    color: #2196f3 !important; /* Default blue color */
}

.text-inverse {
    color: #212121 !important; /* Default dark color */
}
html body {
    .text-blue {
        color: #2196f3; /* Default blue color */
    }

    .text-purple {
        color: #9c27b0; /* Default purple color */
    }

    .text-primary {
        color: #3f51b5; /* Default primary color */
    }

    .text-megna {
        color: #00bcd4; /* Default cyan color */
    }
    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .text-left {
        text-align: left;
    }
}
/*******************
Background Colors
*******************/

.bg-primary {
    background-color: #3f51b5 !important; /* Default primary color */
}

.bg-success {
    background-color: #4caf50 !important; /* Default green color */
}

.bg-info {
    background-color: #2196f3 !important; /* Default blue color */
}

.bg-warning {
    background-color: #ff9800 !important; /* Default orange color */
}

.bg-danger {
    background-color: #f44336 !important; /* Default red color */
}
html body {
    .bg-megna {
        background-color: #00bcd4; /* Default cyan color */
    }
    .bg-theme {
        background-color: #3f51b5; /* Default theme color */
    }

    .bg-inverse {
        background-color: #212121; /* Default dark color */
    }

    .bg-purple {
        background-color: #9c27b0 !important; /* Default purple color */
    }

    .bg-light-primary {
        background-color: #c5cae9; /* Default light primary color */
    }

    .bg-light-success {
        background-color: #c8e6c9; /* Default light green color */
    }

    .bg-light-info {
        background-color: #bbdefb; /* Default light blue color */
    }
    .bg-light-extra {
        background-color: #f0f0f0; /* Default extra light color */
    }

    .bg-light-warning {
        background-color: #ffe0b2; /* Default light orange color */
    }

    .bg-light-danger {
        background-color: #ffcdd2; /* Default light red color */
    }

    .bg-light-inverse {
        background-color: #f5f5f5; /* Default light dark color */
    }
    .bg-light {
        background-color: #f5f5f5; /* Default light color */
    }
    .bg-white {
        background-color: #ffffff; /* Default white color */
    }
}
.img-circle {
    border-radius: 100%;
}
.no-shadow {
    box-shadow: none;
}
hr {
    border: 0px;
    height: 1px;
    background: #e0e0e0; /* Default border color */
}
/*******************
Rounds
*******************/
.round {
    line-height: 48px;
    color: #ffffff; /* Default white color */
    width: 45px;
    height: 45px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: #2196f3; /* Default blue color */
    img {
        border-radius: 100%;
    }
}
.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}
.round.round-info {
    background: #2196f3; /* Default blue color */
}
.round.round-warning {
    background: #ff9800; /* Default orange color */
}
.round.round-danger {
    background: #f44336; /* Default red color */
}
.round.round-success {
    background: #4caf50; /* Default green color */
}
.round.round-primary {
    background: #3f51b5; /* Default primary color */
}
/*******************
Labels
*******************/

.label {
    padding: 3px 10px;
    line-height: 13px;
    color: #ffffff; /* Default white color */
    font-weight: 400;
    border-radius: 4px; /* Default border radius */
    font-size: 75%;
}

.label-rounded {
    border-radius: 60px;
}

.label-custom {
    background-color: #00bcd4; /* Default cyan color */
}

.label-success {
    background-color: #4caf50; /* Default green color */
}

.label-info {
    background-color: #2196f3; /* Default blue color */
}

.label-warning {
    background-color: #ff9800; /* Default orange color */
}

.label-danger {
    background-color: #f44336; /* Default red color */
}

.label-megna {
    background-color: #00bcd4; /* Default cyan color */
}

.label-primary {
    background-color: #3f51b5; /* Default primary color */
}

.label-purple {
    background-color: #9c27b0; /* Default purple color */
}

.label-red {
    background-color: #f44336; /* Default red color */
}

.label-inverse {
    background-color: #212121; /* Default dark color */
}

.label-default {
    background-color: #f5f5f5; /* Default light color */
}

.label-white {
    background-color: #ffffff; /* Default white color */
}

.label-light-success {
    background-color: #c8e6c9; /* Default light green color */
    color: #4caf50; /* Default green color */
}

.label-light-info {
    background-color: #bbdefb; /* Default light blue color */
    color: #2196f3; /* Default blue color */
}

.label-light-warning {
    background-color: #ffe0b2; /* Default light orange color */
    color: #ff9800; /* Default orange color */
}

.label-light-danger {
    background-color: #ffcdd2; /* Default light red color */
    color: #f44336; /* Default red color */
}

.label-light-megna {
    background-color: #b2ebf2; /* Default light cyan color */
    color: #00bcd4; /* Default cyan color */
}

.label-light-primary {
    background-color: #c5cae9; /* Default light primary color */
    color: #3f51b5; /* Default primary color */
}

.label-light-inverse {
    background-color: #f5f5f5; /* Default light dark color */
    color: #212121; /* Default dark color */
}

/*******************
Notify
*******************/

.notify {
    position: relative;
    top: -20px;
    right: 0px;
    .heartbit {
        position: absolute;
        top: -20px;
        right: -4px;
        height: 25px;
        width: 25px;

        border: 5px solid #f44336; /* Default red color */
        border-radius: 70px;
        -moz-animation: heartbit 1s ease-out;
        -moz-animation-iteration-count: infinite;
        -o-animation: heartbit 1s ease-out;
        -o-animation-iteration-count: infinite;
        -webkit-animation: heartbit 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: #f44336; /* Default red color */
        position: absolute;
        right: 6px;
        top: -10px;
    }
}

@-moz-keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0;
    }
    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-lg {
    line-height: 48px !important;
}
/*******************
froms
*******************/
.form-control {
    border: 0px;
    line-height: 25px;
    padding: 10px;
    width: 100%;
    font-size: 16px; /* Default font size */
}
/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.25);
}
.mat-mdc-form-field {
    width: 100%;
}
/*******************
Mat card
*******************/
.mat-mdc-card {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
    margin: 15px;
    padding: 0px !important;
    border-radius: 4px; /* Default border radius */
    &.text-white {
        small {
            color: rgba(255, 255, 255, 0.7) !important;
        }
    }

    .mat-mdc-card-subtitle {
        font-weight: 400;
    }

    .mat-mdc-card-content {
        font-size: 1rem;
        line-height: 23px;
        padding: 24px;
    }
    .mat-mdc-card-image {
        width: calc(100% + 0px);
        margin: 0 0px 0px 0px;
    }
    .mat-mdc-card-header {
        padding: 12px;
        border-radius: 4px 4px 0 0;
        .mat-mdc-card-title {
            margin-bottom: 2px;
            font-size: 20px;
        }
        .mat-mdc-card-subtitle,
        .mat-card-subtitle:not(:first-child) {
            margin-bottom: 0px;
            margin-top: 0px;
            font-size: 14px;
            font-weight: 400;
        }
        &.text-white {
            .mat-mdc-card-title {
                color: #ffffff; /* Default white color */
            }
            small,
            .mat-mdc-card-subtitle {
                color: rgba(255, 255, 255, 0.7) !important;
            }
        }
    }
    .mat-mdc-card-title {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .mat-mdc-card-subtitle {
        margin-bottom: 20px;
    }
    .mat-mdc-card-actions {
        margin: 0px !important;
    }
}

/*******************
Grid
*******************/
.row {
    margin: 0 -10px;
}
/*******************
Table
*******************/
.table {
    width: 100%;
    text-align: left;
}

.mat-mdc-table {
    overflow: auto;
    min-width: 280px;
}
.mat-mdc-header-row {
    .mat-mdc-header-cell {
        white-space: nowrap;
    }
}
.mat-mdc-cell {
    white-space: nowrap;
}

/*******************
Sparkline chart tooltip
*******************/
.jqstooltip {
    width: auto !important;
    height: auto !important;
    background: #212121; /* Default dark color */
    color: #ffffff; /* Default white color */
    padding: 5px 15px;
    border: 0px;
    border-radius: 60px;
}
/*******************
list inline
*******************/
.list-inline {
    margin-left: 0px;
    padding: 0px;
    li {
        display: inline-block;
        list-style: none;
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
    }
}

/*******************
list inline
*******************/
.list-inline {
    margin-left: 0px;
    padding: 0px;
    li {
        display: inline-block;
        list-style: none;
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
    }
}
.button-row button {
    margin-right: 2px;
    margin-left: 2px;
}

.mini-spacer {
    margin-top: 90px;
}

/*******************
  List-style-none
  ******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        a {
            padding: 8px 0px;
            display: block;
            text-decoration: none;
            &:hover {
                /* Hover color can be defined here */
            }
        }
    }
}

.align-self-center {
    align-self: center;
}

.float-right {
    float: right;
}

.list-unstyled {
    margin: 0px;
    padding: 0px;

    li {
        list-style: none;
        display: flex;
        align-items: flex-start;
        padding: 15px 0;
    }
    .shrink-none {
        flex-shrink: 0;
    }
}
.cursor-pointer {
    cursor: pointer;
}

/*******************
Paddings
*******************/
html body {
    .p-0 {
        padding: 0px;
    }

    .p-12 {
        padding: 12px;
    }

    .p-10 {
        padding: 10px;
    }

    .p-15 {
        padding: 15px;
    }

    .p-20 {
        padding: 20px;
    }

    .p-30 {
        padding: 30px;
    }

    .p-l-0 {
        padding-left: 0px;
    }

    .p-l-5 {
        padding-left: 5px;
    }

    .p-r-5 {
        padding-right: 5px;
    }

    .p-l-10 {
        padding-left: 10px;
    }

    .p-r-15 {
        padding-right: 15px;
    }

    .p-l-20 {
        padding-left: 20px;
    }

    .p-l-25 {
        padding-left: 25px;
    }

    .p-r-0 {
        padding-right: 0px;
    }

    .p-r-10 {
        padding-right: 10px;
    }

    .p-r-20 {
        padding-right: 20px;
    }

    .p-r-30 {
        padding-right: 30px;
    }

    .p-r-40 {
        padding-right: 40px;
    }

    .p-t-0 {
        padding-top: 0px;
    }

    .p-t-10 {
        padding-top: 10px;
    }

    .p-t-15 {
        padding-top: 15px;
    }

    .p-t-20 {
        padding-top: 20px;
    }

    .p-t-30 {
        padding-top: 30px;
    }

    .p-b-0 {
        padding-bottom: 0px;
    }

    .p-b-5 {
        padding-bottom: 5px;
    }

    .p-b-10 {
        padding-bottom: 10px;
    }

    .p-b-15 {
        padding-bottom: 15px;
    }

    .p-b-20 {
        padding-bottom: 20px;
    }

    .p-b-30 {
        padding-bottom: 30px;
    }

    .p-b-40 {
        padding-bottom: 40px;
    }

    .p-l-30 {
        padding-left: 30px;
    }

    .p-r-30 {
        padding-right: 30px;
    }
}
.text-dark-black {
    color: rgba(0, 0, 0, 0.87);
}

/*******************
  Margin
  *******************/
html body {
    .m-0 {
        margin: 0px;
    }

    .m-15 {
        margin: 15px;
    }

    .m-l-5 {
        margin-left: 5px;
    }

    .m-l-10 {
        margin-left: 10px;
    }

    .m-l-15 {
        margin-left: 15px;
    }

    .m-l-20 {
        margin-left: 20px;
    }

    .m-l-30 {
        margin-left: 30px;
    }

    .m-l-40 {
        margin-left: 40px;
    }

    .m-r-5 {
        margin-right: 5px;
    }

    .m-r-10 {
        margin-right: 10px;
    }

    .m-r-15 {
        margin-right: 15px;
    }

    .m-r-20 {
        margin-right: 20px;
    }

    .m-r-30 {
        margin-right: 30px;
    }

    .m-r-40 {
        margin-right: 40px;
    }

    .m-t-0 {
        margin-top: 0px;
    }

    .m-t-5 {
        margin-top: 5px;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-t-15 {
        margin-top: 15px;
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-30 {
        margin-top: 30px;
    }

    .m-t-40 {
        margin-top: 40px;
    }

    .m-b-0 {
        margin-bottom: 0px;
    }

    .m-b-5 {
        margin-bottom: 5px;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    .m-b-15 {
        margin-bottom: 15px;
    }

    .m-b-20 {
        margin-bottom: 20px;
    }

    .m-b-30 {
        margin-bottom: 30px;
    }

    .m-b-40 {
        margin-bottom: 40px;
    }
}

.autocomplete-container {
    box-shadow:
        0 0px 0px 0 rgba(0, 0, 0, 0.2),
        0 0px 0px 0 rgba(0, 0, 0, 0.87),
        0 2px 1px -1px #67757c !important;
    height: 24px !important;
}

.autocomplete-container .input-container input {
    font-size: 16px !important;
    padding-left: 0px !important;
    height: 15px !important;
}

.autocomplete-container .input-container {
    height: 24px !important;
    font-size: inherit !important;
}

.mat-sidenav-container,
.mat-sidenav-content {
    transform: none !important;
    transition: none !important;
}

.autocomplete-container .input-container input {
    line-height: 25px !important;
    font-size: inherit !important;
}

.ngx-gallery-preview-text {
    font-size: 25px !important;
    background: rgb(255, 132, 132) !important;
    padding: 20px !important;
}

.ngx-gallery-image-text {
    font-size: 20px !important;
    background: rgb(255, 132, 132) !important;
    padding: 15px !important;
}
